<template>
  <div class="main-container">
    <!--查询区域start-->
    <div class="search-area">
      <div class="form-area">
        <el-form class="search-form" ref="search-form" :model="searchForm" label-width="100px" >
          <el-form-item label="系统模块">
            <el-input v-model="searchForm.sysModule"></el-input>
          </el-form-item>
          <el-form-item label="操作人员">
            <el-input v-model="searchForm.opUser"></el-input>
          </el-form-item>
          <el-form-item label="操作类型">
            <el-select placeholder="请选择" v-model="searchForm.opType" clearable>
              <el-option
                v-for="item in opTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="请求方式">
            <el-select placeholder="请选择" v-model="searchForm.reqMethod" clearable>
              <el-option
                v-for="item in reqMethodList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="请求状态">
            <el-select v-model="searchForm.opStatus">
              <el-option label="全部" value=""></el-option>
              <el-option label="成功" value="1"></el-option>
              <el-option label="失败" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="操作时间">
            <el-date-picker
              v-model="searchForm.opTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right">
            </el-date-picker>
          </el-form-item>
          <el-form-item label-width="0">
            <el-button type="primary" class="search-btn" @click="queryUserList">查询</el-button>
            <el-button class="reset-btn" @click="resetBtn">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!--查询区域end-->

    <!-- 表格区域start -->
    <div class="table-area">
      <div class="operate-btn">
        <!--            <el-button v-check-auth="['sys#user#add1']" type="primary" class="add-btn" @click="addDialogOpen"><i class="el-icon-plus"></i>导出</el-button>-->
        <el-button type="primary" class="add-btn" @click="addDialogOpen"><i class="el-icon-download"></i>导出</el-button>
      </div>
      <el-table
        ref="resultTable"
        :data="tableList"
        tooltip-effect="dark"
        v-loading="tableLoading">
        <el-table-column
          prop="userId"
          label="日志编号"
          min-width="180">
        </el-table-column>
        <el-table-column
          prop="account"
          label="系统模块"
          min-width="180">
        </el-table-column>
        <el-table-column
          prop="nickname"
          label="操作类型"
          min-width="180">
        </el-table-column>
        <el-table-column
          prop="mobilePhone"
          label="请求方式"
          min-width="180">
        </el-table-column>
        <el-table-column
          prop="mobilePhone"
          label="请求状态"
          min-width="180">
        </el-table-column>
        <el-table-column
          prop="mobilePhone"
          label="操作时间"
          min-width="180">
        </el-table-column>
        <el-table-column
          prop="createData"
          label="操作人"
          min-width="180">
        </el-table-column>
        <el-table-column
          label="操作"
          min-width="260">
          <template>
            <el-button type="text">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-area">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="paginationConfig.pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="paginationConfig.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="paginationConfig.total"> </el-pagination>
      </div>
    </div>
    <!-- 表格区域end -->
  </div>
</template>

<script>
export default {
  name: 'TableStatistic',
  data() {
    return {
      opTypeList: [
        {
          value: 1,
          label: '查询'
        },
        {
          value: 2,
          label: '新增'
        },
        {
          value: 3,
          label: '修改'
        },
        {
          value: 4,
          label: '删除'
        },
        {
          value: 5,
          label: '越权'
        },
        {
          value: 6,
          label: '导出'
        },
        {
          value: 7,
          label: '备份'
        },
        {
          value: 8,
          label: '恢复'
        },
        {
          value: 9,
          label: '容量告警'
        }
      ],
      reqMethodList: [
        {
          value: 1,
          label: 'GET'
        },
        {
          value: 2,
          label: 'POST'
        },
        {
          value: 3,
          label: 'PUT'
        },
        {
          value: 4,
          label: 'DELETE'
        }
      ],
      // 分页数据
      paginationConfig: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      searchForm: {

      }
    }
  },
  methods: {
    /**
     * 分页事件
     */
    // 页大小切换
    handleSizeChange(val) {
      this.paginationConfig.pageSize = val
    },
    // 页码变更
    handleCurrentChange(val) {
      this.paginationConfig.pageNum = val
    }
  }
}
</script>

<style scoped lang="scss">
@import "~$assets/css/common/search-area.scss";
@import "~$assets/css/common/table-area.scss";
.main-container{
  width: 100%;
  box-sizing: border-box;
  .search-area{
    margin: 0;
    padding: 0;
  }
  .table-area{
    margin: 0;
    padding: 0;
  }
  .pagination-area{
    display: flex;
    justify-content: flex-end;
  }
}
</style>
